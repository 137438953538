<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div>
          <b-row>
            <!-- <b-col md="2">
              <b-form-group label="Bet Type">
                <v-select
                  v-model="selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="option"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="From.">
                <b-form-datepicker
                  id="example-datepicker1"
                  v-model="value1"
                  class="mb-1"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="To.">
                <b-form-datepicker
                  id="example-datepicker2"
                  v-model="value"
                  class="mb-1"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Username">
                <b-form-input
                  id="basicInput"
                  placeholder="Username"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-row>
                <b-col md="9">
                  <b-form-group label="Transaction">
                    <b-form-input
                      id="basicInput"
                      placeholder="Transaction"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label=".">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col> -->
            <b-col md="2">
              <b-form-group label=".">
                <v-select
                  v-model="selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="selectedOp"
                  :reduce="title => title.value"
                  @input="getwinloss"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
      <!-- <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template> -->
      <!-- Column: Actions -->
      <!-- <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editData(data.item)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
      </b-table> -->

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <!-- <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getDeposit()"
          />
        </b-form-group> -->

        <!-- pagination -->
        <!-- <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getDeposit()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div> -->
        <table class="styled-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Turnover</th>
              <th>Win/Lose</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in winloss.betall"
              :key="item"
            >
              <td>
                <router-link
                  class="text-info"
                  :to="{
                    name: 'History-Play',
                    params: { id: item._id },
                  }"
                >
                  {{ item._id }}</router-link>
              </td>
              <td>{{ item.turn }}</td>
              <td
                v-if="Number(item.winloss) < 0"
                class="text-danger"
              >
                {{ item.winloss }}
              </td>
              <td v-else>
                {{ item.winloss }}
              </td>
            </tr>
            <tr style="background-color: #2e5d92">
              <th class="text-white">
                Total
              </th>
              <th class="text-white">
                {{ winloss.tturn }}
              </th>
              <th
                v-if="Number(winloss.twinloss) < 0"
                class="text-danger"
              >
                {{ winloss.twinloss }}
              </th>
              <th v-else>
                {{ winloss.twinloss }}
              </th>
            </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BRow,
  BCol,
  // BFormDatepicker,
  BOverlay,
  BIconController,
  BCard,
  //  BTable,
  //  BAvatar,
  BFormGroup,
  //  BFormSelect,
  //  BPagination,
  // BFormInput,
  // BButton,
  BCardBody,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BCard,
    // BTable,
    // BAvatar,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    // BFormInput,
    // BInputGroupAppend,
    // BButton,
    BCardBody,
    BOverlay,
    BIconController,
    // BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      selected: 'Today',
      selectedOp: [
        { title: 'Today', value: 'Today' },
        { title: 'Yesterday', value: 'Yesterday' },
        { title: 'This Week', value: 'ThisWeek' },
        { title: 'Last Week', value: 'LastWeek' },
        { title: 'This Month', value: 'ThisMonth' },
        { title: 'Last Month', value: 'LastMonth' },
      ],
      emailValue: '',
      week_day: '',
      time: null,
      time2: null,
      name: '',
      required,
      email,
      search_val: null,
      show: true,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      week: [
        {
          value: 'today',
          text: 'to day',
        },
      ],
      timeOp1: [
        { value: '00', text: '00' },
        { value: '01', text: '01' },
        { value: '02', text: '02' },
        { value: '03', text: '03' },
        { value: '04', text: '04' },
        { value: '05', text: '05' },
        { value: '06', text: '06' },
        { value: '07', text: '07' },
        { value: '08', text: '08' },
        { value: '09', text: '09' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' },
        { value: '13', text: '13' },
        { value: '14', text: '14' },
        { value: '15', text: '15' },
        { value: '16', text: '16' },
        { value: '17', text: '17' },
        { value: '18', text: '18' },
        { value: '19', text: '19' },
        { value: '20', text: '20' },
        { value: '21', text: '21' },
        { value: '22', text: '22' },
        { value: '23', text: '23' },
      ],
      timeOp2: [
        { value: '00', text: '00' },
        { value: '01', text: '01' },
        { value: '02', text: '02' },
        { value: '03', text: '03' },
        { value: '04', text: '04' },
        { value: '05', text: '05' },
        { value: '06', text: '06' },
        { value: '07', text: '07' },
        { value: '08', text: '08' },
        { value: '09', text: '09' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' },
        { value: '13', text: '13' },
        { value: '14', text: '14' },
        { value: '15', text: '15' },
        { value: '16', text: '16' },
        { value: '17', text: '17' },
        { value: '18', text: '18' },
        { value: '19', text: '19' },
        { value: '20', text: '20' },
        { value: '21', text: '21' },
        { value: '22', text: '22' },
        { value: '23', text: '23' },
        { value: '24', text: '24' },
        { value: '25', text: '25' },
        { value: '26', text: '26' },
        { value: '27', text: '27' },
        { value: '28', text: '28' },
        { value: '29', text: '29' },
        { value: '30', text: '30' },
        { value: '31', text: '31' },
        { value: '32', text: '32' },
        { value: '33', text: '33' },
        { value: '34', text: '34' },
        { value: '35', text: '35' },
        { value: '36', text: '36' },
        { value: '37', text: '37' },
        { value: '38', text: '38' },
        { value: '39', text: '39' },
        { value: '40', text: '40' },
        { value: '41', text: '41' },
        { value: '42', text: '42' },
        { value: '43', text: '43' },
        { value: '44', text: '44' },
        { value: '45', text: '45' },
        { value: '46', text: '46' },
        { value: '47', text: '47' },
        { value: '48', text: '48' },
        { value: '49', text: '49' },
        { value: '50', text: '50' },
        { value: '51', text: '51' },
        { value: '52', text: '52' },
        { value: '53', text: '53' },
        { value: '54', text: '54' },
        { value: '55', text: '55' },
        { value: '56', text: '56' },
        { value: '57', text: '57' },
        { value: '58', text: '58' },
        { value: '59', text: '59' },
      ],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'username' },
        { key: 'Specail', label: 'Specail' },
        { key: 'win_lose', label: 'Win/Lose' },
        // {
        //   key: 'created_at',
        //   label: 'เวลา',
        //   formatter: value => moment(value)
        //     .tz('Asia/Bangkok')
        //     .format('DD/MM/YYYY HH:mm:ss'),
        // },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      winloss: '',
      Interval: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  created() {
    this.Interval = setInterval(() => {
      this.getUserslist()
      this.getDeposit()
    }, 30000)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getUserslist()
    this.getDeposit()
    this.getwinloss()
  },
  methods: {
    async getwinloss() {
      this.show = true
      await this.$http
        .get(`https://api.rmtlotto.com/api/history/winloss/${this.selected}`)
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.winloss = response.data
          // console.log(this.monthly.regis)
        })
        .catch(error => console.log(error), (this.show = false))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getUserslist() {
      this.$http
        .get('https://api.rmtlotto.com/api/users/list')
        .then(response => {
          this.show = false
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    getDeposit() {
      let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
      }
      this.$http
        .get('https://api.rmtlotto.com/api/deposit', { params })
        .then(response => {
          this.show = false
          this.depositdata = response.data.data
          this.depositdata.forEach(items => {
            this.getAdmin(items.addby, index)
            index += 1
          })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    getAdmin(addby, index) {
      this.$http
        .get(`https://api.rmtlotto.com/api/admin/show/${addby}`)
        .then(response => {
          this.show = false
          // console.log(response.data.name)
          this.depositdata[index].addby = response.data.name
        })
        .catch(error => console.log(error))
    },
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.time && this.time2) {
            this.show = true
            const formData = {
              username: this.member.username,
              uid: this.member.id,
              amount: this.amount,
              time: `${this.time}:${this.time2}`,
            }
            this.$http
              .post('https://api.rmtlotto.com/api/deposit/store', formData)
              .then(() => {
                this.show = false
                this.getDeposit()
                this.time = null
                this.time2 = null
                this.member = ''
                this.amount = ''
                this.Success('เติมเครดิต สำเร็จ')
              })
              .catch(error => {
                this.show = false
                this.SwalError(error.response.data.message)
              })
          } else {
            this.show = false
            this.SwalError('กรุณาใส่ให้ครบทุกช่อง')
          }
        }
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 100%;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}
.styled-table thead tr {
  background-color: #d40000;
  color: #ffffff;
  border-radius: 10px;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #d40000;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #d40000;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';

.form-item-section {
  background-color: $product-details-bg;
}
</style>
